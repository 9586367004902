import { Minimize } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { memo, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  MaintenanceEvent,
  MAINTENANCE_INCIDENT_LEVEL,
  MAINTENANCE_STATUS,
  MAINTENANCE_TYPE,
  SERVICE_NAMES,
} from '../../api/types';
import { Inputs } from './types';

type Props = {
  editData?: MaintenanceEvent;
  isRegistering: boolean;
  onSubmit: (data: Inputs) => void;
};

const MaintenanceForm = ({ editData, isRegistering, onSubmit }: Props) => {
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { isValid },
  } = useForm<Inputs>({
    mode: 'all',
    defaultValues: editData
      ? {
          start_time: DateTime.fromISO(editData.start_time),
          end_time: editData.end_time
            ? DateTime.fromISO(editData.end_time)
            : DateTime.fromISO(editData.start_time).plus({ hours: 1 }),
          target_services: editData.target_services,
          status: editData.status,
          type: editData.type,
          incident_level:
            editData.incident_level ?? MAINTENANCE_INCIDENT_LEVEL[0],
          summary_en: editData.summary.en,
          summary_ja: editData.summary.ja,
          is_end_time: !!editData.end_time,
        }
      : {
          start_time: DateTime.now(),
          end_time: DateTime.now().plus({ hours: 1 }),
          target_services: [],
          status: MAINTENANCE_STATUS[0],
          type: MAINTENANCE_TYPE[0],
          incident_level: MAINTENANCE_INCIDENT_LEVEL[0],
          summary_en: '',
          summary_ja: '',
          is_end_time: false,
        },
  });
  const type = useWatch({ control, name: 'type' });
  const startTime = useWatch({ control, name: 'start_time' });
  const endTime = useWatch({ control, name: 'end_time' });
  const isEndTime = useWatch({ control, name: 'is_end_time' });

  const validationRules = useMemo(() => {
    return {
      start_time: {
        validate: (val: DateTime | null) => {
          if (val === null) {
            return 'Plase enter';
          }
          if (!val.isValid) {
            return 'Invalid Date';
          }
          if (
            isEndTime &&
            endTime &&
            endTime.isValid &&
            val.startOf('minute') >= endTime.startOf('minute')
          ) {
            return 'Please set before the end time';
          }
          clearErrors('end_time');
          return true;
        },
      },
      end_time: {
        validate: (val: DateTime | null | undefined) => {
          if (!isEndTime) {
            return true;
          }
          if (val === null || val === undefined) {
            return 'Plase enter';
          }
          if (!val.isValid) {
            return 'Invalid date';
          }
          if (
            startTime &&
            startTime.isValid &&
            val.startOf('minute') <= startTime.startOf('minute')
          ) {
            return 'Please set after the start time';
          }
          clearErrors('start_time');
          return true;
        },
      },
      target_services: {
        validate: (val: string[]) => {
          if (val.length === 0) {
            return 'Please select';
          }
          return true;
        },
      },
    };
  }, [startTime, endTime, isEndTime, clearErrors]);

  return (
    <Box
      mt={6}
      sx={{
        pointerEvents: isRegistering ? 'none' : 'auto',
      }}
    >
      <Paper>
        <Box p={8}>
          <Box>
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl
                  required
                  disabled={!!editData}
                  error={!!fieldState.error}
                >
                  <FormLabel>Type</FormLabel>
                  <RadioGroup row value={field.value}>
                    {MAINTENANCE_TYPE.map((type, i) => (
                      <FormControlLabel
                        {...field}
                        label={type}
                        key={`type${i}`}
                        value={type}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>
          {type === 'unplanned' && (
            <Box mt={4} mb={6}>
              <Controller
                name="incident_level"
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl required error={!!fieldState.error}>
                    <InputLabel shrink>Incident Level</InputLabel>
                    <Select
                      notched
                      label="Incident Level"
                      sx={{ minWidth: 210 }}
                      {...field}
                    >
                      {MAINTENANCE_INCIDENT_LEVEL.map((type, i) => (
                        <MenuItem key={`incident_level${i}`} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Box>
          )}
          <Box mt={4}>
            <Controller
              name="status"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl required error={!!fieldState.error}>
                  <InputLabel shrink>Status</InputLabel>
                  <Select
                    notched
                    label="Status"
                    sx={{ minWidth: 210 }}
                    {...field}
                  >
                    {MAINTENANCE_STATUS.map((status, i) => (
                      <MenuItem key={`status${i}`} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Box>
          {type === 'unplanned' && (
            <Box mt={3}>
              <Controller
                name="is_end_time"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label="Set end time? (Do not check if end time is undecided.)"
                    control={<Checkbox {...field} checked={field.value} />}
                  />
                )}
              />
            </Box>
          )}
          <Box mt={4}>
            <Grid container spacing={4}>
              <Grid item>
                <Controller
                  name="start_time"
                  control={control}
                  rules={validationRules.start_time}
                  render={({ field, fieldState }) => (
                    <DateTimePicker
                      disablePast
                      inputRef={field.ref}
                      format="yyyy/MM/dd HH:mm"
                      views={['year', 'month', 'day', 'hours', 'minutes']}
                      slotProps={{
                        textField: {
                          required: true,
                          label: 'Start',
                          InputLabelProps: {
                            shrink: true,
                          },
                          onBlur: field.onBlur,
                          name: field.name,
                          error: isEndTime ? !!fieldState.error : false,
                          helperText: isEndTime
                            ? fieldState.error?.message
                            : '',
                        },
                      }}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Box mt={2}>
                  <Minimize />
                </Box>
              </Grid>
              <Grid item>
                <Controller
                  name="end_time"
                  control={control}
                  rules={validationRules.end_time}
                  render={({ field, fieldState }) => (
                    <DateTimePicker
                      disablePast
                      disabled={type === 'unplanned' && !isEndTime}
                      inputRef={field.ref}
                      format="yyyy/MM/dd HH:mm"
                      views={['year', 'month', 'day', 'hours', 'minutes']}
                      slotProps={{
                        textField: {
                          required: true,
                          label: 'End',
                          InputLabelProps: {
                            shrink: true,
                          },
                          onBlur: field.onBlur,
                          name: field.name,
                          error: !!fieldState.error,
                          helperText: fieldState.error?.message,
                        },
                      }}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={6}>
            <Controller
              name="summary_en"
              control={control}
              rules={{
                required: 'Please enter',
                maxLength: {
                  value: 256,
                  message: 'Please enter up to 256 characters',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  label="Summary - EN"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </Box>
          <Box mt={6}>
            <Controller
              name="summary_ja"
              control={control}
              rules={{
                required: 'Please enter',
                maxLength: {
                  value: 256,
                  message: 'Please enter up to 256 characters',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  label="Summary - JA"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </Box>
          <Box mt={6}>
            <Controller
              name="target_services"
              control={control}
              rules={validationRules.target_services}
              render={({ field, fieldState }) => (
                <Autocomplete
                  multiple
                  ref={field.ref}
                  defaultValue={editData?.target_services ?? []}
                  options={SERVICE_NAMES}
                  onChange={(_, newValue) => {
                    setValue('target_services', newValue, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                  }}
                  onBlur={field.onBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Services"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name={field.name}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Box>
          {!!editData && (
            <Box mt={3}>
              <Grid container spacing={12}>
                <Grid item>
                  <Typography variant="caption" color="gray">
                    ID
                  </Typography>
                  <Typography>{editData.id}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption" color="gray">
                    Updated At
                  </Typography>
                  <Typography>
                    {DateTime.fromISO(editData.last_updated_time).toFormat(
                      'yyyy/MM/dd HH:mm:ss',
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box mt={8} textAlign="center">
            <LoadingButton
              sx={{
                width: 200,
              }}
              loading={isRegistering}
              variant="contained"
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
            >
              {editData ? 'Update' : 'Register'}
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default memo(MaintenanceForm);
